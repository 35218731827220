            /* Home slider
    =====================================*/
    jQuery( document ).ready(function($) {
        var swiper = new Swiper(".swiper-wrapper-brand", {
            direction: 'horizontal',
            loop: true,
            slidesPerView: 1,
            centeredSlides: true,
            loopAdditionalSlides: 1,
            effect: 'fade',
            speed: 3500,
            fadeEffect: {
            crossFade: true
            },
            loopFillGroupWithBlank: true,
            spaceBetween: 20,
            autoplay: true,
             breakpoints: {
                1330: {
                    slidesPerView: 1,
                    
                }
             }, 
          });
    });
    jQuery( document ).ready(function($) {
    var swiper = new Swiper(".d-news-preview__slider", {
        direction: 'horizontal',
        loop: true,
        slidesPerView: 3,
    /*      effect: 'fade', */
        speed: 3500,
        fadeEffect: {
        crossFade: true
        },
        loopFillGroupWithBlank: true,
        spaceBetween: 20,
        autoplay: true,
        breakpoints: {
        320: {
             slidesPerView: 1,
             spaceBetween: 15,
        },
        767: {
            slidesPerView: 2,
             },
        1330: {
            slidesPerView: 3,
            
        }
        }, 
        });
    });
    jQuery( document ).ready(function($) {
        var swiper = new Swiper(".recommendations__slider", {
            direction: 'horizontal',
            loop: true,
            slidesPerView: 3,
        /*      effect: 'fade', */
            speed: 3500,
            fadeEffect: {
            crossFade: true
            },
            loopFillGroupWithBlank: true,
            spaceBetween: 20,
            autoplay: true,
            breakpoints: {
                320: {
                     slidesPerView: 1,
                     spaceBetween: 15,
                },
                767: {
                    slidesPerView: 2,
                     },
                1330: {
                    slidesPerView: 3,
                    
                }
            }, 
            });
    });