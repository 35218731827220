jQuery(document).ready(function($) {
    var type_appartment = $('#type_appartment');
    var category_appartment = $('#category_appartment');
    var areaMinInput = $('#areaMin');
    var areaMaxInput = $('#areaMax');
    var priceMinInput = $('#priceMin');
    var priceMaxInput = $('#priceMax');
    var districtSelect = $('#district');
    var metroSelect = $('#metro');
    var residentialComplexSelect = $('#nvl');
    var streetSelect = $('#street');
    var idInput = $('#id');
    var searchButton = $('.filter__btn');
    var productIdCurrency = $('.productIdCurrency');
    var prevParams = {
        typeAppartment: '',
        categoryAppartment: '',
        typeNvl: '',
        typeDistrict: '',
        typeStreet: '',
        typeAreaMin: '',
        typeAreaMax: '',
        typePriceMin: '',
        typePriceMax: '',
        roomCount: '',
        stateValues: '',
        id: ''
    };
    
    $(document).on("click", ".type_appartment", function () {
        $(".type_appartment").not(this).removeClass("active");
        $(this).addClass("active");
        updateUrl(); 
        updateTypeAppartmentLabel();
    });
    function getTypeAppartment() {
		var obTaxonomy1 = [];
		$(".type_appartment.active").each(function() {
			var atid = $(this).attr('data-id');
			obTaxonomy1.push(atid)
		});
		return obTaxonomy1;
	}
    $(document).on("click", ".cat__item", function () {
        $(".cat__item").not(this).removeClass("active");
        $(this).addClass("active");
        updateUrl(); 
        updateCategoryAppartmentLabel();
    });
    function getCategoryAppartment() {
		var obTaxonomy = [];
		$(".cat__item.active").each(function() {
			var atid = $(this).attr('data-category');
			obTaxonomy.push(atid)
		});
		return obTaxonomy;
	}
    $(document).on("click", ".type_district", function () {
        $(".type_district").not(this).removeClass("active");
        $(this).addClass("active");
        updateUrl(); 
        updateDistrictLabel();
    });
    function getTypeDistrict() {
		var obTaxonomy1 = [];
		$(".type_district.active").each(function() {
			var atid = $(this).attr('data-id');
			obTaxonomy1.push(atid)
		});
		return obTaxonomy1;
	}
    $(document).on("click", ".type_nvl", function () {
        $(".type_nvl").not(this).removeClass("active");
        $(this).addClass("active");
        updateUrl(); 
        updateResidentialComplexLabel();
    });
    function getTypeNvl() {
		var obTaxonomy = [];
		$(".type_nvl.active").each(function() {
			var atid = $(this).attr('data-id');
			obTaxonomy.push(atid)
		});
		return obTaxonomy;
	}
    $(document).on("click", ".type_street", function () {
        $(".type_street").not(this).removeClass("active");
        $(this).addClass("active");
      /*   auto_s(); */
        updateUrl();
    });
    function getTypeStreet() {
		var obTaxonomy = [];
		$(".type_street.active").each(function() {
			var atid = $(this).attr('data-id');
			obTaxonomy.push(atid)
		});
		return obTaxonomy;
	}
    $(document).on("click", ".themes-sort-select li", function() {
        $(".themes-sort-select li").not(this).removeClass("active");
        $(this).addClass("active");
        var selectedText = $(this).text();
        $(".title-sort").text(selectedText);
        updateUrl();
        auto_s();
    });
    function getTypeSort() {
		var obTaxonomy = [];
		$(".themes-sort-select li.active").each(function() {
			var atid = $(this).attr('value');
			obTaxonomy.push(atid)
		});
		return obTaxonomy;
	}
    areaMinInput.on('input', function() {
        updateUrl(); 
    });
    areaMaxInput.on('input', function() {
        updateUrl(); 
    });
    priceMinInput.on('input', function() {
        updateUrl(); 
    });
    priceMaxInput.on('input', function() {
        updateUrl(); 
    });
    idInput.on('input', function() {
        updateUrl();
    });


    $(document).on("click", ".room .filter__input-label", function () {
        $(this).find('.filter__input-label-checked').toggleClass('active'); 
        updateUrl(); 
    });
    function getRoomCountValues() {
        var roomCountValues = [];
        $('.room .filter__input-label-checked.active').each(function() {
            var value = $(this).data('value');
            roomCountValues.push(value);
        });
        return roomCountValues;
    }


    $(document).on("click", ".state .filter__input-label", function () {
        $('.state .filter__input-label-checked').removeClass('active');
        $(this).find('.filter__input-label-checked').toggleClass('active'); 
       
        updateUrl(); 
    });
    function getStateValues() {
        var stateValues = [];
        $('.state .filter__input-label-checked.active').each(function() {
            var value = $(this).data('value');
            stateValues.push(value);
        });
        return stateValues;
    }



    function updateUrl() {
        var newUrl = window.location.href;
        var typeAppartment = getTypeAppartment().join(',') || '';
        var categoryAppartment = getCategoryAppartment().join(',') || '';
        var typeNvl = getTypeNvl().join(',') || '';
        var typeDistrict = getTypeDistrict().join(',') || '';
        var typeStreet = getTypeStreet().join(',') || '';
        var typeAreaMin = areaMinInput.val() || '';
        var typeAreaMax = areaMaxInput.val() || '';
        var typePriceMin = priceMinInput.val() || '';
        var typePriceMax = priceMaxInput.val() || '';
        var roomCount =   getRoomCountValues().join(',') || '';
        var id = idInput.val() || '';
        var sort =   getTypeSort().join(',') || '';
        var typeState = getStateValues().join(',') || '';
        

        if (typeAppartment !== "") {
            newUrl = updateQueryStringParameter(newUrl, 'type_appartment', typeAppartment); 
        }
        if (categoryAppartment !== "") {
            newUrl = updateQueryStringParameter(newUrl, 'category_appartment', categoryAppartment);
        }
        if (typeNvl !== "") {
            newUrl = updateQueryStringParameter(newUrl, 'nvl', typeNvl);
        }
        if (typeDistrict !== "") {
            newUrl = updateQueryStringParameter(newUrl, 'district', typeDistrict);
        }
        if (typeStreet !== "") {
            newUrl = updateQueryStringParameter(newUrl, 'street', typeStreet);
        }
        if (typeAreaMin !== "") {
            newUrl = updateQueryStringParameter(newUrl, 'areaMin', typeAreaMin);
        }
        if (typeAreaMax !== "") {
            newUrl = updateQueryStringParameter(newUrl, 'areaMax', typeAreaMax);
        }
        if (typePriceMin !== "") {
            newUrl = updateQueryStringParameter(newUrl, 'priceMin', typePriceMin);
        }
        if (typePriceMax !== "") {
            newUrl = updateQueryStringParameter(newUrl, 'priceMax', typePriceMax);
        }
        if (roomCount !== "") {
            newUrl = updateQueryStringParameter(newUrl, 'roomCount', roomCount);
        }
        if (id !== "") {
            newUrl = updateQueryStringParameter(newUrl, 'id', id);
        }
        if (sort !== "") {
            newUrl = updateQueryStringParameter(newUrl, 'sort', sort);
        }
        if (typeState !== "") {
            newUrl = updateQueryStringParameter(newUrl, 'typeState', typeState);
        }

        
        history.pushState(null, null, newUrl);
        console.log(newUrl);
    }
    function removeEmptyParams(url) {
        var cleanUrl = new URL(url);
        var searchParams = cleanUrl.searchParams;
        searchParams.forEach((value, key) => {
            if (value === "" || value === "undefined") {
                searchParams.delete(key);
            }
        });
        return cleanUrl.toString();
    }
    function updateQueryStringParameter(uri, key, value) {
        var url = new URL(uri);
        url.searchParams.set(key, value);
        return url.toString();
    }
    function auto_s() {
        var typeAppartment = getTypeAppartment().join(',');
        var categoryAppartment = getCategoryAppartment().join(',');
        var typeNvl = getTypeNvl().join(',');
        var typeDistrict = getTypeDistrict().join(',');
        var typeStreet = getTypeStreet().join(',');
        var typeAreaMin = areaMinInput.val();
        var typeAreaMax = areaMaxInput.val();
        var typePriceMin = priceMinInput.val();
        var typePriceMax = priceMaxInput.val();
        var roomCount = getRoomCountValues().join(',');
        var typeState = getStateValues().join(',');
        var id = idInput.val();
        var sort = getTypeSort().join(',');
        var params = {
            action: 'block_callback_btn_section',
            type_appartment: decodeURIComponent(typeAppartment),
            category_appartment: decodeURIComponent(categoryAppartment),
            get_nvl: decodeURIComponent(typeNvl),
            get_district: decodeURIComponent(typeDistrict),
            get_street: decodeURIComponent(typeStreet),
            get_area_min: typeAreaMin,
            get_area_max: typeAreaMax,
            get_price_min: typePriceMin,
            roomCount: roomCount,
            get_price_max: typePriceMax,
            id: id, 
            sort: sort,
            typeState: typeState,
            security: my_script_vars.nonce
        };
        var currentParams = {
            typeAppartment: getTypeAppartment().join(','),
            categoryAppartment: getCategoryAppartment().join(','),
            typeNvl: getTypeNvl().join(','),
            typeDistrict: getTypeDistrict().join(','),
            typeStreet: getTypeStreet().join(','),
            typeAreaMin: areaMinInput.val(),
            typeAreaMax: areaMaxInput.val(),
            typePriceMin: priceMinInput.val(),
            typePriceMax: priceMaxInput.val(),
            roomCount: getRoomCountValues().join(','),
            typeState: getStateValues().join(','),
            id: idInput.val()
        };
        $.ajax({
            url: my_script_vars.ajax_url,
            type: 'GET',
            data: params,
            success: function(data) {
                console.log(data);
                if (data.success) {
                    window.location.href = data.data.url;
                }
                jQuery(document).ready(function($) {
                    function saveCurrency(currency) {
                        document.cookie = "currency=" + currency + "; path=/";
                    }
                    $(document).ready(function() {
                        const $currencyButtons = $('.currency-btn');
                        const $priceDisplays = $('.priceDisplay');
                        $currencyButtons.on('click', function() {
                        const currency = $(this).data('currency');
                        saveCurrency(currency);
                            function getPriceTranslation(language) {
                                switch(language) {
                                    case 'ru':
                                        return 'Цена';
                                    case 'en':
                                        return 'Price';
                                    case 'uk':
                                    default:
                                        return 'Ціна';
                                }
                            }
                            $priceDisplays.each(function() {
                            const price = $(this).attr(`data-price-${currency}`);
                            $(this).text(`${getPriceTranslation($(this).attr('lang'))}: ${price} ${currency}`);
                            });
                        });
                    });
                      function getCookie(name) {
                        var value = "; " + document.cookie;
                        var parts = value.split("; " + name + "=");
                        if (parts.length === 2) return parts.pop().split(";").shift();
                    }
                    var currencyCookie = getCookie("currency");
                    if (currencyCookie) {
                        $('.currency-btn[data-currency="' + currencyCookie + '"]').trigger('click');
                    }
                });
                if (JSON.stringify(prevParams) !== JSON.stringify(currentParams)) {
                    // Обновляем метки
                    updateResidentialComplexLabel();
                    updateCategoryAppartmentLabel();
                    updateTypeAppartmentLabel();
                    updateStreetLabel();
                    updateDistrictLabel();
                    prevParams = Object.assign({}, currentParams);
                }
            },
            error: function(error) {
                console.error(error);
            }
        });
    }
    function auto_s_filter() {
        var typeAppartment = getTypeAppartment().join(',');
        var categoryAppartment = getCategoryAppartment().join(',');
        var typeNvl = getTypeNvl().join(',');
        var typeDistrict = getTypeDistrict().join(',');
        var typeStreet = getTypeStreet().join(',');
        var typeAreaMin = areaMinInput.val();
        var typeAreaMax = areaMaxInput.val();
        var typePriceMin = priceMinInput.val(); 
        var typePriceMax = priceMaxInput.val(); 
        var roomCount = getRoomCountValues().join(',');
        var id = idInput.val();
        var typeState = getStateValues().join(',');
        var sortBy = $(".themes-sort-select li.active").attr("value");
        var typeProductIdCurrency = productIdCurrency.val();
        var params = {
            action: 'block_callback_btn_section',
            type_appartment: decodeURIComponent(typeAppartment),
            category_appartment: decodeURIComponent(categoryAppartment),
            get_nvl: decodeURIComponent(typeNvl),
            get_district: decodeURIComponent(typeDistrict),
            get_street: decodeURIComponent(typeStreet),
            get_area_min: typeAreaMin,
            get_area_max: typeAreaMax,
            get_price_min: typePriceMin,
            roomCount: roomCount,
            get_price_max: typePriceMax,
            id: id, 
            sort: sortBy,
            typeState: typeState,
            get_currency: typeProductIdCurrency,
            security: my_script_vars.nonce
        };
        
        $.ajax({
            url: my_script_vars.ajax_url,
            type: 'GET',
            data: params,
            beforeSend: function ()
			{
				$('.post-main').css("opacity" , "0.3");
                $('.loader-wrapper').css("display" , "flex");
			},
			success: function(data)
			{
                if (data.success) {
                    var newFoundPostsCount = data.data.found_posts;
                    $('.found-post').text(newFoundPostsCount);
                } else {
                    console.log('Ошибка при получении данных');
                }
                console.log(data);
                $('.post-main').css("opacity" , "1");
                $('.post-main').html(data);
                /*  $('.page-search').removeClass('loadings');
                $('.page-search').removeClass('inner'); */
                $('.loader-wrapper').css("display" , "none");
                jQuery(document).ready(function($) {
                    function saveCurrency(currency) {
                        document.cookie = "currency=" + currency + "; path=/";
                    }
                    $(document).ready(function() {
                        const $currencyButtons = $('.currency-btn');
                        const $priceDisplays = $('.priceDisplay');
                        $currencyButtons.on('click', function() {
                        const currency = $(this).data('currency');
                        saveCurrency(currency);
                            function getPriceTranslation(language) {
                                switch(language) {
                                    case 'ru':
                                        return 'Цена';
                                    case 'en':
                                        return 'Price';
                                    case 'uk':
                                    default:
                                        return 'Ціна';
                                }
                            }
                            $priceDisplays.each(function() {
                                const price = $(this).attr(`data-price-${currency}`);
                                $(this).text(`${getPriceTranslation($(this).attr('lang'))}: ${price} ${currency}`);
                            });
                        });
                    });
                      function getCookie(name) {
                        var value = "; " + document.cookie;
                        var parts = value.split("; " + name + "=");
                        if (parts.length === 2) return parts.pop().split(";").shift();
                    }
                    var currencyCookie = getCookie("currency");
                    if (currencyCookie) {
                        $('.currency-btn[data-currency="' + currencyCookie + '"]').trigger('click');
                    }
                });
			},
            error: function (jqXHR, textStatus, errorThrown) {
                console.log('Ошибка: ' + textStatus);
                console.log('Описание ошибки: ' + errorThrown);
                console.log('HTTP-код ошибки: ' + jqXHR.status);
            }
        });
    }

    function updateTypeAppartmentLabel() {
        var currentTypeAppartment = getTypeAppartment().join(',');
        if (currentTypeAppartment !== prevParams.typeAppartment) {
            var updateTypeAppartmentLabel = $('.type_appartment.active').text();
            $('.main-filter #type_appartment label').text(updateTypeAppartmentLabel);
            prevParams.typeAppartment = currentTypeAppartment; 
        }
    }
    
    function updateCategoryAppartmentLabel() {
        var currentCategoryAppartment = getCategoryAppartment().join(',');
        if (currentCategoryAppartment !== prevParams.categoryAppartment) {
            var updateCategoryAppartmentLabel = $('.cat__item.active').text();
            $('.main-filter #category_appartment label').text(updateCategoryAppartmentLabel);
            prevParams.categoryAppartment = currentCategoryAppartment;
        }
    }
    
    function updateDistrictLabel() {
        var currentDistrict = getTypeDistrict().join(',');
        if (currentDistrict !== prevParams.typeDistrict) {
            var updateDistrictLabel = $('.type_district.active').text();
            $('.main-filter #district label').text(updateDistrictLabel);
            prevParams.typeDistrict = currentDistrict; 
        }
    }
    
    function updateResidentialComplexLabel() {
        var currentResidentialComplex = getTypeNvl().join(',');
        if (currentResidentialComplex !== prevParams.typeNvl) {
            var selectedResidentialComplex = $('.type_nvl.active').text();
            $('.main-filter #nvl label').text(selectedResidentialComplex);
            prevParams.typeNvl = currentResidentialComplex; 
        }
    }
    
    function updateStreetLabel() {
        var currentStreet = getTypeStreet().join(',');
        if (currentStreet !== prevParams.typeStreet) {
            var updateStreetLabel = $('.type_street.active').text();
            $('.main-filter #street label').text(updateStreetLabel);
            prevParams.typeStreet = currentStreet; // Обновляем предыдущее значение
        }
    }


    $(document).ready(function() {
        updateTypeAppartmentLabel();
        updateCategoryAppartmentLabel();
        updateDistrictLabel();
        updateResidentialComplexLabel();
        updateStreetLabel();
    });

    

    $('.filter__btn').on('click', function() {
        auto_s(); 
    });
    /* СБРОС */
    function clearQueryString(url) {
        var cleanUrl = new URL(url);
        cleanUrl.search = '';
        return cleanUrl.toString();
    }
    var originalTypeAppartmentLabel = $('.main-filter #type_appartment label').text();
    var originalCategoryAppartmentLabel = $('.main-filter #category_appartment label').text();
    var originalDistrictLabel = $('.main-filter #district label').text();
    var originalResidentialComplexLabel = $('.main-filter #nvl label').text();
    var originalStreetLabel = $('.main-filter #street label').text();

    $('.filter__reset-btn').on('click', function() {
        $('.main-filter #type_appartment label').text(originalTypeAppartmentLabel);
    $('.main-filter #category_appartment label').text(originalCategoryAppartmentLabel);
    $('.main-filter #district label').text(originalDistrictLabel);
    $('.main-filter #nvl label').text(originalResidentialComplexLabel);
    $('.main-filter #street label').text(originalStreetLabel);
        
        $('.type_appartment.active').removeClass('active');
        $('.cat__item.active').removeClass('active');
        $('.type_district.active').removeClass('active');
        $('li.type_nvl[data-id="nvl"]').addClass('active');
        /* $('.type_nvl.active').removeClass('active'); */
        $('.type_street.active').removeClass('active');
        areaMinInput.val('');
        areaMaxInput.val('');
        priceMinInput.val('');
        priceMaxInput.val('');
        $('.room .filter__input-label-checked.active').removeClass('active');
        $('.state .filter__input-label-checked.active').removeClass('active');
        // Очищаем параметры в URL
        var cleanUrl = clearQueryString(window.location.href);
        history.pushState(null, null, cleanUrl);
    });
    /* СБРОС */
});
jQuery(document).ready(function($) {
    $('.main__row-btn-filter').on('click', function() {
        $('.form-rows').slideToggle();
    });
});
jQuery(document).ready(function($) {
    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
    
    // Получение параметра "type_appartment" из URL
    var typeAppartmentParam = getUrlParameter('type_appartment');
    var categoryAppartmentParam = getUrlParameter('category_appartment');
    
    // Добавление класса "active" к соответствующему элементу
    if (typeAppartmentParam !== '') {
        $('.page-search .type_appartment[data-id="' + typeAppartmentParam + '"]').addClass('active');
    }
    if (categoryAppartmentParam !== '') {
        $('.page-search .cat__item[data-category="' + categoryAppartmentParam + '"]').addClass('active');
    }
    // Получение параметра "nvl" из URL
        var nvlParam = getUrlParameter('nvl');

        // Добавление класса "active" к соответствующему элементу
        if (nvlParam !== '') {
            $('.page-search .type_nvl[data-id="' + nvlParam + '"]').addClass('active');
        }

        // Получение параметра "district" из URL
        var districtParam = getUrlParameter('district');

        // Добавление класса "active" к соответствующему элементу
        if (districtParam !== '') {
            $('.page-search .type_district[data-id="' + districtParam + '"]').addClass('active');
        }

        // Получение параметра "street" из URL
        var streetParam = getUrlParameter('street');

        // Добавление класса "active" к соответствующему элементу
        if (streetParam !== '') {
            $('.page-search .type_street[data-id="' + streetParam + '"]').addClass('active');
        }

        // Получение параметра "roomscount" из URL
        var roomscountParam = getUrlParameter('roomCount');

        // Разделение значения параметра на отдельные значения (если выбрано несколько)
        var roomscountValues = roomscountParam.split(',');

        // Добавление класса "active" к соответствующим элементам
        if (roomscountParam !== '') {
            // Предположим, что roomscount представлен чекбоксами с классом .filter__input-label-checked и атрибутом data-value, соответствующим значению комнат
            roomscountValues.forEach(function(value) {
                $('.room .filter__input-label-checked[data-value="' + value + '"]').addClass('active');
            });
        }


        var statevaluescountParam = getUrlParameter('stateValues'); 

        // Разделение значения параметра на отдельные значения (если выбрано несколько)
        var statecountValues = statevaluescountParam.split(',');

        // Добавление класса "active" к соответствующим элементам
        if (statecountValues !== '') {
            // Предположим, что roomscount представлен чекбоксами с классом .filter__input-label-checked и атрибутом data-value, соответствующим значению комнат
            statecountValues.forEach(function(value) {
                $('.state .filter__input-label-checked[data-value="' + value + '"]').addClass('active');
            });
        }


        var idParam = getUrlParameter('id');

        // Добавление класса "active" к соответствующему элементу
        if (idParam !== '') {
            // Предположим, что элементы с ID представлены элементами с классом .id-item и атрибутом data-id, соответствующим значению ID
            $('.id-item[data-id="' + idParam + '"]').addClass('active');
        }


        var areaMinParam = getUrlParameter('areaMin');
        var areaMaxParam = getUrlParameter('areaMax');
        var priceMinParam = getUrlParameter('priceMin');
        var priceMaxParam = getUrlParameter('priceMax');
        var idParam = getUrlParameter('id');

        // Применение параметров к соответствующим элементам формы
        if (areaMinParam !== '') {
            $('#areaMin').val(areaMinParam);
        }
        if (areaMaxParam !== '') {
            $('#areaMax').val(areaMaxParam);
        }
        if (priceMinParam !== '') {
            $('#priceMin').val(priceMinParam);
        }
        if (priceMaxParam !== '') {
            $('#priceMax').val(priceMaxParam);
        }
        if (idParam !== '') {
            $('#id').val(idParam);
        }

});


/* ФИЛЬТ НА СТРАНИЦЕ ПОИСКА */
jQuery(document).ready(function($) {
    var type_appartment = $('.page-search #type_appartment');
    var category_appartment = $('.page-search #category_appartment');
    var areaPageMinInput = $('.page-search #areaMin');
    var areaPageMaxInput = $('.page-search #areaMax');
    var pricePageMinInput = $('.page-search #priceMin');
    var pricePageMaxInput = $('.page-search #priceMax');
    var districtSelect = $('.page-search #district');
    var metroSelect = $('.page-search #metro');
    var residentialComplexSelect = $('.page-search #nvl');
    var streetSelect = $('.page-search #street');
    var idPageInput = $('.page-search #id');
    var searchPageButton = $('.page-search .filter__btn');
    var prevParams = {
        typeAppartment: '',
        categoryAppartment: '',
        typeNvl: '',
        typeDistrict: '',
        typeStreet: '',
        typeAreaMin: '',
        typeAreaMax: '',
        typePriceMin: '',
        typePriceMax: '',
        roomCount: '',
        id: ''
    };
    $(document).on("click", ".page-search .type_appartment", function () {
        $(".page-search .type_appartment").not(this).removeClass("active");
        $(this).addClass("active");
        updatePageUrl(); 
        updateTypeAppartmentLabel()
    });
    function getPageTypeAppartment() {
		var obTaxonomy1 = [];
		$(".page-search .type_appartment.active").each(function() {
			var atid = $(this).attr('data-id');
			obTaxonomy1.push(atid)
		});
		return obTaxonomy1;
	}
    $(document).on("click", ".page-search .cat__item", function () {
        $(".page-search .cat__item").not(this).removeClass("active");
        $(this).addClass("active");
        updatePageUrl(); 
        updateCategoryAppartmentLabel();
    });

/*  updateTypeAppartmentLabel();
    updateCategoryAppartmentLabel();
    updateDistrictLabel();
    updateResidentialComplexLabel();
    updateStreetLabel(); */
    function getPageCategoryAppartment() {
		var obTaxonomy = [];
		$(".page-search .cat__item.active").each(function() {
			var atid = $(this).attr('data-category');
			obTaxonomy.push(atid)
		});
		return obTaxonomy;
	}
    $(document).on("click", ".page-search .type_district", function () {
        $(".page-search .type_district").not(this).removeClass("active");
        $(this).addClass("active");
        updatePageUrl(); 
        updateDistrictLabel();
    });
    function getPageTypeDistrict() {
		var obTaxonomy1 = [];
		$(".page-search .type_district.active").each(function() {
			var atid = $(this).attr('data-id');
			obTaxonomy1.push(atid)
		});
		return obTaxonomy1;
	}
    $(document).on("click", ".page-search .type_nvl", function () {
        $(".page-search .type_nvl").not(this).removeClass("active");
        $(this).addClass("active");
        updatePageUrl(); 
        updateResidentialComplexLabel()
    });
    function getPageTypeNvl() {
		var obTaxonomy = [];
		$(".page-search .type_nvl.active").each(function() {
			var atid = $(this).attr('data-id');
			obTaxonomy.push(atid)
		});
		return obTaxonomy;
	}
    $(document).on("click", ".page-search .type_street", function () {
        $(".page-search .type_street").not(this).removeClass("active");
        $(this).addClass("active");
        /* auto_search_page(); */
        updatePageUrl();
        updateStreetLabel(); 
    });
    function getPageTypeStreet() {
		var obTaxonomy = [];
		$(".page-search .type_street.active").each(function() {
			var atid = $(this).attr('data-id');
			obTaxonomy.push(atid)
		});
		return obTaxonomy;
	}
    areaPageMinInput.on('input', function() {
        updatePageUrl(); 
    });
    areaPageMaxInput.on('input', function() {
        updatePageUrl(); 
    });
    pricePageMinInput.on('input', function() {
        updatePageUrl(); 
    });
    pricePageMaxInput.on('input', function() {
        updatePageUrl(); 
    });
    idPageInput.on('input', function() {
        updatePageUrl();
    });

    $(document).on("click", ".page-search .filter__input-label", function () {
        $(this).find('.page-search .filter__input-label-checked').toggleClass('active'); 
        updatePageUrl(); 
    });
    // Функция для получения выбранных значений чекбоксов
    function getPageRoomCountValues() {
        var roomCountValues = [];
        $('.page-search .filter__input-label-checked.active').each(function() {
            var value = $(this).data('value');
            roomCountValues.push(value);
        });
        return roomCountValues;
    }
   

    function updateTypeAppartmentLabel() {
        var currentTypeAppartment = getPageTypeAppartment().join(',');
        if (currentTypeAppartment !== prevParams.typeAppartment) {
            var updateTypeAppartmentLabel = $('.page-search .type_appartment.active').text();
            $('.main-filter #type_appartment label').text(updateTypeAppartmentLabel);
            prevParams.typeAppartment = currentTypeAppartment; // Обновляем предыдущее значение
        }
    }
    
    function updateCategoryAppartmentLabel() {
        var currentCategoryAppartment = getPageCategoryAppartment().join(',');
        if (currentCategoryAppartment !== prevParams.categoryAppartment) {
            var updateCategoryAppartmentLabel = $('.page-search .cat__item.active').text();
            $('.main-filter #category_appartment label').text(updateCategoryAppartmentLabel);
            prevParams.categoryAppartment = currentCategoryAppartment; // Обновляем предыдущее значение
        }
    }
    
    function updateDistrictLabel() {
        var currentDistrict = getPageTypeDistrict().join(',');
        if (currentDistrict !== prevParams.typeDistrict) {
            var updateDistrictLabel = $('.page-search .type_district.active').text();
            $('.main-filter #district label').text(updateDistrictLabel);
            prevParams.typeDistrict = currentDistrict; // Обновляем предыдущее значение
        }
    }
    
    function updateResidentialComplexLabel() {
        var currentResidentialComplex = getPageTypeNvl().join(',');
        if (currentResidentialComplex !== prevParams.typeNvl) {
            var selectedResidentialComplex = $('.page-search .type_nvl.active').text();
            $('.main-filter #residentialComplexSelect').text(selectedResidentialComplex);
            prevParams.typeNvl = currentResidentialComplex; // Обновляем предыдущее значение
        }
    }
    
    function updateStreetLabel() {
        var currentStreet = getPageTypeStreet().join(',');
        if (currentStreet !== prevParams.typeStreet) {
            var updateStreetLabel = $('.page-search .type_street.active').text();
            $('.main-filter #street label').text(updateStreetLabel);
            prevParams.typeStreet = currentStreet; // Обновляем предыдущее значение
        }
    }


    $(document).ready(function() {
        updateTypeAppartmentLabel();
        updateCategoryAppartmentLabel();
        updateDistrictLabel();
        updateResidentialComplexLabel();
        updateStreetLabel();
    });


/*     var typeAppartmentParam = getUrlParameter('type_appartment');
    var typeCategoryParam = getUrlParameter('category_appartment');
    var typeNvlParam = updateResidentialComplexLabel();
    
    if (typeAppartmentParam) {
        $('#type_appartment label').text(typeAppartmentParam);
    }
    if (typeCategoryParam) {
        $('#category_appartment label').text(typeCategoryParam);
    }
    if (typeNvlParam) {
        $('#nvl label').text(typeNvlParam);
    }

    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    } */



    
    
    function updatePageUrl() {
        var newUrl = window.location.href;
        var typeAppartment = getPageTypeAppartment().join(',') || '';
        var categoryAppartment = getPageCategoryAppartment().join(',') || '';
        var typeNvl = getPageTypeNvl().join(',') || '';
        var typeDistrict = getPageTypeDistrict().join(',') || '';
        var typeStreet = getPageTypeStreet().join(',') || '';

        var typeAreaMin = areaPageMinInput.val() || '';
        var typeAreaMax = areaPageMaxInput.val() || '';
        var typePriceMin = pricePageMinInput.val() || '';
        var typePriceMax = pricePageMaxInput.val() || '';
        var roomCount =   getPageRoomCountValues().join(',') || '';
        var id = idPageInput.val() || '';

        if (typeAppartment !== "") {
            newUrl = updatePageQueryStringParameter(newUrl, 'type_appartment', typeAppartment); 
        }
        if (categoryAppartment !== "") {
            newUrl = updatePageQueryStringParameter(newUrl, 'category_appartment', categoryAppartment);
        }
        if (typeNvl !== "") {
            newUrl = updatePageQueryStringParameter(newUrl, 'nvl', typeNvl);
        }
        if (typeDistrict !== "") {
            newUrl = updatePageQueryStringParameter(newUrl, 'district', typeDistrict);
        }
        if (typeStreet !== "") {
            newUrl = updatePageQueryStringParameter(newUrl, 'street', typeStreet);
        }
        if (typeAreaMin !== "") {
            newUrl = updatePageQueryStringParameter(newUrl, 'areaMin', typeAreaMin);
        }
        if (typeAreaMax !== "") {
            newUrl = updatePageQueryStringParameter(newUrl, 'areaMax', typeAreaMax);
        }
        if (typePriceMin !== "") {
            newUrl = updatePageQueryStringParameter(newUrl, 'priceMin', typePriceMin);
        }
        if (typePriceMax !== "") {
            newUrl = updatePageQueryStringParameter(newUrl, 'priceMax', typePriceMax);
        }
        if (roomCount !== "") {
            newUrl = updatePageQueryStringParameter(newUrl, 'roomCount', roomCount);
        }
        if (id !== "") {
            newUrl = updatePageQueryStringParameter(newUrl, 'id', id);
        }

        history.pushState(null, null, newUrl);
        console.log(newUrl);
    }

    function removePageEmptyParams(url) {
        var cleanUrl = new URL(url);
        var searchParams = cleanUrl.searchParams;
        searchParams.forEach((value, key) => {
            if (value === "" || value === "undefined") {
                searchParams.delete(key);
            }
        });
        return cleanUrl.toString();
    }

    function updatePageQueryStringParameter(uri, key, value) {
        var url = new URL(uri);
        url.searchParams.set(key, value);
        return url.toString();
    }

    function auto_search_page() {
        var typeAppartment = getPageTypeAppartment().join(',');
        var categoryAppartment = getPageCategoryAppartment().join(',');
        var typeNvl = getPageTypeNvl().join(',');
        var typeDistrict = getPageTypeDistrict().join(',');
        var typeStreet = getPageTypeStreet().join(',');
        var typeAreaMin = areaPageMinInput.val();
        var typeAreaMax = areaPageMaxInput.val();
        var typePriceMin = pricePageMinInput.val(); // Исправлено
        var typePriceMax = pricePageMaxInput.val(); // Исправлено
        var roomCount = getPageRoomCountValues().join(',');
        var id = idPageInput.val();

        var params = {
            action: 'block_callback_page_search',
            type_appartment: decodeURIComponent(typeAppartment),
            category_appartment: decodeURIComponent(categoryAppartment),
            get_nvl: decodeURIComponent(typeNvl),
            get_district: decodeURIComponent(typeDistrict),
            get_street: decodeURIComponent(typeStreet),
            get_area_min: typeAreaMin,
            get_area_max: typeAreaMax,
            get_price_min: typePriceMin,
            roomCount: roomCount,
            get_price_max: typePriceMax,
            id: id, 
            security: my_script_vars.nonce
        };
        var currentParams = {
            typeAppartment: getPageTypeAppartment().join(','),
            categoryAppartment: getPageCategoryAppartment().join(','),
            typeNvl: getPageTypeNvl().join(','),
            typeDistrict: getPageTypeDistrict().join(','),
            typeStreet: getPageTypeStreet().join(','),
            typeAreaMin: areaPageMinInput.val(),
            typeAreaMax: areaPageMaxInput.val(),
            typePriceMin: pricePageMinInput.val(),
            typePriceMax: pricePageMaxInput.val(),
            roomCount: getPageRoomCountValues().join(','),
            id: idPageInput.val()
        };
        $.ajax({
            url: my_script_vars.ajax_url,
            type: 'GET',
            data: params,
            beforeSend: function ()
			{
				$('.post-main').css("opacity" , "0.3");
                $('.loader-wrapper').css("display" , "flex");
			},
			success: function(data)
			{
                if (data.success) {
                    var newFoundPostsCount = data.data.found_posts;
                    // Обновляем содержимое блока с количеством найденных записей
                    $('.found-post').text(newFoundPostsCount);
                } else {
                    // Обработка случая, если данные не были успешно получены
                    console.log('Ошибка при получении данных');
                }
                console.log(data);
                $('.post-main').css("opacity" , "1");
                $('.post-main').html(data);
                /*  $('.page-search').removeClass('loadings');
                $('.page-search').removeClass('inner'); */
                $('.loader-wrapper').css("display" , "none");
                /* NVL TEXT */
            if (JSON.stringify(prevParams) !== JSON.stringify(currentParams)) {
                // Обновляем метки
                updateResidentialComplexLabel();
                updateCategoryAppartmentLabel();
                updateTypeAppartmentLabel();
                updateStreetLabel();
                updateDistrictLabel();
                prevParams = Object.assign({}, currentParams);
            }
			},
            error: function (jqXHR, textStatus, errorThrown) {
                console.log('Ошибка: ' + textStatus);
                console.log('Описание ошибки: ' + errorThrown);
                console.log('HTTP-код ошибки: ' + jqXHR.status);
            }
        });
    }
    $('.search__btn').on('click', function() {
        auto_search_page(); 
    });

    /* СБРОС */
    // Значения по умолчанию для меток
    var defaultTypeAppartmentLabel = "Тип нерухомості";
    var defaultCategoryAppartmentLabel = "Оренда/Продаж";
    var defaultDistrictLabel = "Район";
    var defaultResidentialComplexLabel = "Новопечерські липки";
    var defaultStreetLabel = "Вулиця";
    function setDefaultLabels() {
        $('.main-filter #type_appartment label').text(defaultTypeAppartmentLabel);
        $('.main-filter #category_appartment label').text(defaultCategoryAppartmentLabel);
        $('.main-filter #district label').text(defaultDistrictLabel);
        $('.main-filter #residentialComplexSelect').text(defaultResidentialComplexLabel);
        $('.main-filter #street label').text(defaultStreetLabel);
    }
    function clearPageQueryString(url) {
        var cleanUrl = new URL(url);
        cleanUrl.search = '';
        return cleanUrl.toString();
    }
    $('.page-search .filter__reset-btn').on('click', function() {
        // Очищаем все параметры фильтра
        $('.page-search .type_appartment.active').removeClass('active');
        $('.page-search .cat__item.active').removeClass('active');
        $('.page-search .type_district.active').removeClass('active');
        $('.page-search .type_nvl.active').removeClass('active');
        $('.page-search .type_street.active').removeClass('active');
        areaPageMinInput.val('');
        areaPageMaxInput.val('');
        pricePageMinInput.val('');
        pricePageMaxInput.val('');
        $('.page-search .filter__input-label-checked.active').removeClass('active');
        setDefaultLabels();
        // Очищаем параметры в URL
        var cleanUrl = clearPageQueryString(window.location.href);
        history.pushState(null, null, cleanUrl);
        // Вызываем функцию auto_search_page для сброса AJAX запроса
       /*  auto_search_page(); */
        // Опционально, если есть другие действия для сброса, вы можете добавить их здесь
        /* alert('Фильтр сброшен.'); */
    });
    /* СБРОС */

});
/* LOAD MORE */
jQuery( function( $ ) {
    if ($('#loadmore').length) {
        var ajax_url = "/wp-admin/admin-ajax.php";
        var button = $( '#loadmore a' ),
        paged = button.data('paged'),
        maxPages = button.data( 'max_pages' );
        var projectsContainerId = '.gallery-images__grid.post-main'; // контейнер, в который добалять вновь загруженные данные
        var distanceFromBottomToStartLoad = 800;
        var loadAjax = false;
        let  scrollBottom = function(){ return $(window).scrollTop() + $(window).height(); };
        var roomCount = getRoomCountValues().join(',');

        function getRoomCountValues() {
            var roomCountValues = [];
            $('.filter__input-label-checked.active').each(function() {
                var value = $(this).data('value');
                roomCountValues.push(value);
            });
            return roomCountValues;
        }


        $( window ).scroll( function() {

        if (($(window).scrollTop() + $(window).height() > $(document).height() - distanceFromBottomToStartLoad) && !loadAjax) {
            console.log('infinit load event!!');
            if( ! $( 'body' ).hasClass( 'loading' ) ) {
                $.ajax({
                    type : 'GET',
                    url: ajax_url,
                
                    data : {
                        page : paged,
                        action : 'loadmore',
                        roomCount : roomCount,
                        category : button.data('category'),
                        pagenumlink : button.data('pagenumlink')
                    },
                    dataType: 'json',
                    beforeSend : function( xhr ) {
                        button.text( 'Загружаем...' );
                        $( 'body' ).addClass( 'loading' );
                        /*  $('.gallery-images__grid' ).addClass( 'loader' ); */
                    },
                    success : function( data ){
                        console.log(data);
                        paged++;
                        $(projectsContainerId).append(data.posts);
                        button.text( 'Загрузити більше' );
                        $('body').removeClass('loading');
                        jQuery(document).ready(function($) {
  
                            function saveCurrency(currency) {
                                // Устанавливаем куки с выбранной валютой
                                document.cookie = "currency=" + currency + "; path=/";
                            }
                        
                        // Обработчик события клика для кнопок валют
                            $(document).ready(function() {
                        
                                
                                // Получаем все кнопки валют
                                const $currencyButtons = $('.currency-btn');
                        
                                // Получаем все элементы, содержащие цены
                                const $priceDisplays = $('.priceDisplay');
                            
                                // Добавляем обработчик клика для каждой кнопки валюты
                                $currencyButtons.on('click', function() {
                                    // Получаем выбранную валюту из data-атрибута кнопки
                                    const currency = $(this).data('currency');
                                    saveCurrency(currency);
                                    function getPriceTranslation(language) {
                                        switch(language) {
                                            case 'ru':
                                                return 'Цена';
                                            case 'en':
                                                return 'Price';
                                            case 'uk':
                                            default:
                                                return 'Ціна';
                                        }
                                    }
                                    // Обновляем отображаемые цены для каждого элемента с классом priceDisplay
                                    $priceDisplays.each(function() {
                                        // Получаем цену для соответствующей валюты из data-атрибута элемента
                                        const price = $(this).attr(`data-price-${currency}`);
                                        
                                        // Обновляем содержимое элемента с новой ценой
                                        $(this).text(`${getPriceTranslation($(this).attr('lang'))}: ${price} ${currency}`);
                                    });
                                });
                            });
                              // Функция для получения значения куки по его имени
                              function getCookie(name) {
                                var value = "; " + document.cookie;
                                var parts = value.split("; " + name + "=");
                                if (parts.length === 2) return parts.pop().split(";").shift();
                            }
                        
                            // При загрузке страницы проверяем, есть ли куки с выбранной валютой
                            var currencyCookie = getCookie("currency");
                            if (currencyCookie) {
                                // Если куки найдены, устанавливаем соответствующую валюту
                                $('.currency-btn[data-currency="' + currencyCookie + '"]').trigger('click');
                            }
                        });
                        if( paged == maxPages ) {
                            button.remove();
                            $('body').addClass( 'btn-none' );
                        }
                        /* $('.gallery-images__grid' ).removeClass('loader'); */
                    }
                });
            }
        }
        } );
        button.click( function( event ) {
        event.preventDefault();
        } );
        } 
    } 
);
/* END LOAD MORE */
/* LOAD MORE SEARCH*/
jQuery( function( $ ) {
    if ($('#loadmores').length) {
        var ajax_url = "/wp-admin/admin-ajax.php";
        var button_load = $( '#loadmores');
        var button = $( '#loadmores a'),
        paged = button.data('paged'),
        maxPages = button.data( 'max_pages' );
        var projectsContainerId = '.gallery-images__grid.search-home'; // контейнер, в который добалять вновь загруженные данные
        var distanceFromBottomToStartLoad = 800;
        var loadAjax = false;
        let  scrollBottom = function(){ 
            return $(window).scrollTop() + $(window).height(); 
        };
        var areaMinInput = $('#areaMin');
        var areaMaxInput = $('#areaMax');
        var priceMinInput = $('#priceMin');
        var priceMaxInput = $('#priceMax');
        var idInput = $('#id');
        var roomCount = getRoomCountValues().join(',');
        var typeState = getStateValues().join(',');
        
        var typeAreaMin = areaMinInput.val();
        var typeAreaMax = areaMaxInput.val();
        var typePriceMin = priceMinInput.val(); // Исправлено
        var typePriceMax = priceMaxInput.val(); // Исправлено
        var id = idInput.val();
        var sort = getTypeSort().join(',');
        var nvl = getTypeNvl().join(',');
        var district = getTypeDistrict().join(',');
        var type_appartment = getTypeAppartment().join(',');
        function getTypeAppartment() {
            var obTaxonomy1 = [];
            $(".type_appartment.active").each(function() {
                var atid = $(this).attr('data-id');
                obTaxonomy1.push(atid)
            });
            return obTaxonomy1;
        }
        function getRoomCountValues() {
            var roomCountValues = [];
            $('.room .filter__input-label-checked.active').each(function() {
                var value = $(this).data('value');
                roomCountValues.push(value);
            });
            return roomCountValues;
        }
        function getStateValues() {
            var stateValues = [];
            $('.state .filter__input-label-checked.active').each(function() {
                var value = $(this).data('value');
                stateValues.push(value);
            });
            return stateValues;
        }
        function getTypeSort() {
            var obTaxonomy = [];
            $(".themes-sort-select li.active").each(function() {
                var atid = $(this).attr('value');
                obTaxonomy.push(atid)
            });
            return obTaxonomy;
        }
        function getTypeNvl() {
            var obTaxonomy = [];
            $(".type_nvl.active").each(function() {
                var atid = $(this).attr('data-id');
                obTaxonomy.push(atid)
            });
            return obTaxonomy;
        }
        function getTypeDistrict() {
            var obTaxonomy1 = [];
            $(".type_district.active").each(function() {
                var atid = $(this).attr('data-id');
                obTaxonomy1.push(atid)
            });
            return obTaxonomy1;
        }
        $( window ).scroll( function() {
                if (($(window).scrollTop() + $(window).height() > $(document).height() - distanceFromBottomToStartLoad) && !loadAjax &&
                paged < maxPages) {
                    console.log('infinit load event!!');
                    if( ! $( 'body' ).hasClass( 'loadings' ) ) {
                        $.ajax({
                            type : 'GET',
                            url: ajax_url,
                        
                            data : {
                                page : paged,
                                action : 'loadmore_search',
                                category : button.data('category'),

                                type_appartment: type_appartment,
                                roomCount: roomCount,
                                get_area_min: typeAreaMin,
                                get_area_max: typeAreaMax,
                                get_price_min: typePriceMin,
                                get_price_max: typePriceMax,
                                id: id,
                                sort: sort,
                                nvl: nvl,
                                district: district,
                                typeState:typeState,
                                pagenumlink : button.data('pagenumlink')
                            },
                            dataType: 'json',
                            beforeSend : function( xhr ) {
                                button.text( 'Загружаем...' );
                                $( 'body' ).addClass( 'loadings' );
                                $( '#loadmores' ).addClass( 'loading-scroll' );
                                
                                /*  $('.gallery-images__grid' ).addClass( 'loader' ); */
                            },
                            success : function( data ){
                                //console.log(data);
                                paged++;
                                $(projectsContainerId).append(data.posts);
                                button.text( 'Загрузити більше' );
                                $('body').removeClass('loadings');
                                jQuery(document).ready(function($) {
                                    function saveCurrency(currency) {
                                        document.cookie = "currency=" + currency + "; path=/";
                                    }
                                    $(document).ready(function() {
                                        const $currencyButtons = $('.currency-btn');
                                        const $priceDisplays = $('.priceDisplay');
                                        $currencyButtons.on('click', function() {
                                        const currency = $(this).data('currency');
                                        saveCurrency(currency);
                                        function getPriceTranslation(language) {
                                            switch(language) {
                                                case 'ru-RU':
                                                    return 'Цена';
                                                case 'en-US':
                                                    return 'Price';
                                                case 'uk':
                                                default:
                                                    return 'Ціна';
                                            }
                                        }
                                        // Обновляем отображаемые цены для каждого элемента с классом priceDisplay
                                        $priceDisplays.each(function() {
                                            // Получаем цену для соответствующей валюты из data-атрибута элемента
                                            const price = $(this).attr(`data-price-${currency}`);
                                            
                                            // Обновляем содержимое элемента с новой ценой
                                            $(this).text(`${getPriceTranslation($(this).attr('lang'))}: ${price} ${currency}`);
                                        });
                                        });
                                    });
                                    function getCookie(name) {
                                        var value = "; " + document.cookie;
                                        var parts = value.split("; " + name + "=");
                                        if (parts.length === 2) return parts.pop().split(";").shift();
                                    }
                                    var currencyCookie = getCookie("currency");
                                    if (currencyCookie) {
                                        $('.currency-btn[data-currency="' + currencyCookie + '"]').trigger('click');
                                    }
                                });
                                if( paged == maxPages ) {
                                    button.remove();
                                    button_load.remove();
                                    $('body').addClass( 'btn-none' );
                                }
                                $('#loadmores').removeClass('loading-scroll');
                            }
                        });
                    }
                }
            } 
        );
        button.click( function( event ) { event.preventDefault();} );
        } 
    } 
);
/* END LOAD MORE SEARCH*/
/* MAIN */

jQuery( document ).ready(function($) {
    

    $('#burger').click(function() {
        $('.icon-btn__fill').toggleClass('burger-btn--open');
        $('body').toggleClass('overflow_hidden');
        $('.header-main').toggleClass('open-menu');
        $('.header-main-open').toggleClass('open-modal');
        
    });
});
jQuery( document ).ready(function($) {


$('#burger-mob').click(function() {
    $('.icon-btn__fill').toggleClass('burger-btn--open');
    $('body').toggleClass('overflow_hidden');
    $('.header-main').toggleClass('open-menu');
    $('.header-main-open').toggleClass('open-modal');
    
});
});
jQuery(document).ready(function($) {
    function closeOnOutsideClick(element) {
        $(document).on('click', function(event) {
            if (!$(event.target).closest(element).length) {
                $(element).removeClass('active');
            }
        });
    }
    $('.met-top-visible').click(function() {
        $(this).toggleClass('active');
        closeOnOutsideClick(this); 
    });
    $('.mob-click-menu').click(function() {
        $(this).toggleClass('active');
        closeOnOutsideClick(this); 
    });
});;
jQuery(document).ready(function($) {
    $('.header-main-open-links').on('click', '.header-main-open-links__item.not_active', function() {
        $('.header-main-open-links__item').removeClass('active');
        $('.header-main-open-links__item').not(this).addClass('hide');
        $(this).addClass('active');
    });
    $('.header-main-open-links').on('click', '.header-main-open-links__item.not_active.active', function() {
        $('.header-main-open-links__item').removeClass('hide active');
    });
    $('.header-main-open-links__item').removeClass('hide');
});
jQuery(document).ready(function($) {
    var maxHeight = 0;
    $(".d-news-preview-item__title").each(function() {
        var currentHeight = $(this).height();
        if (currentHeight > maxHeight) {
        maxHeight = currentHeight;
        }
    });
    $(".d-news-preview-item__title").height(maxHeight);
});
jQuery( document ).ready(function($) {
    $('#nav_toggle').click(function() {
        $('.header_bar').toggleClass('hidden');
        $('.menu_block').toggleClass('hidden');
        $('.main').toggleClass('hidden-main');
    });
    $('#nav_toggle-close').click(function() {
        $('.header_bar').toggleClass('hidden');
        $('.menu_block').toggleClass('hidden');
        $('.main').toggleClass('hidden-main');
    });
});
jQuery( document ).ready(function($) {
    jQuery.fancybox.defaults.thumbs.autoStart = true;
    $('.fancy_main').fancybox({
        animationEffect : 'fade',
        buttons: [
            "zoom",
            "slideShow",
            "fullScreen",
            "thumbs",
            "close"
        ],	    
        thumbs : {
            autoStart : true,
            axis: 'y'
        }
     });
    $('[data-modal=consultation]').on('click', function() {
        $('.overlay, #consultation').fadeIn('slow');
    });
    $('.modal__close').on('click', function() {
        $('.overlay, #consultation, #thanks, #order').fadeOut('slow');
    });
    
    $('.button_mini').each(function(i) {
        $(this).on('click', function() {
            $('#order .modal__descr').text($('.catalog-item__subtitle').eq(i).text());
            $('.overlay, #order').fadeIn('slow');
        })
    }); 
});
jQuery( document ).ready(function($) {    
    window.onscroll = () => {
    const header = document.querySelector('.header-main');
    const Y = window.scrollY;
    if ( Y > 100) {
        header.classList.add('header_fixed');		
    } else if ( Y < 100) {			
        header.classList.remove('header_fixed');	
    }		
};
$(document).ready(function() {
    $('#custom_phone').mask('+38(000)000-00-00');
    $('#tel').mask('+38(000)000-00-00');
    $('#tel_single').mask('+38(000)000-00-00');
  });
});


/* ФОРМЫ */
jQuery( document ).ready(function($) {  
    $("[data-fancybox]").fancybox();
    $("#myForm").submit(function(event) {
        event.preventDefault();

        if (!$('#agree_callMe').prop('checked')) {
            $('.d-input__error').addClass('active');
            return false; // Предотвращаем отправку формы
        } else {
            $('.d-input__error').removeClass('active');
        }

        // Отправка данных на сервер с использованием AJAX
        var formDataMain = {
            action: 'block_callback_modal',
            block_callback_name: $('#name_modal').val(),
            block_callback_phone: $('#tel').val(),
            block_callback_email: $('#email').val(),
            block_callback_rent: $('#rent').prop('checked') ? 'Аренда' : '',
            block_callback_sale: $('#sale').prop('checked') ? 'Продажа' : '',
            block_callback_message: $('#message').val(),
        };
        var ajaxurl = '/wp-admin/admin-ajax.php';
        $.ajax({
            type: 'POST',
            url: ajaxurl,
            data: formDataMain,
            success: function(data) {
                console.log('Успешно');
                $('.d-input__error').removeClass('active');
                $('body').addClass('hide-b');
                $('#myForm')[0].reset();
                $.fancybox.open({
                    src: '#thank_you_modal',
                    type: 'inline'
                });
                
            }
        });

        // Закрываете модальное окно после отправки формы
        $.fancybox.close();
    });
});
jQuery( document ).ready(function($) {  
    $("[data-fancybox]").fancybox();
    $("#myFormSingle").submit(function(event) {
        event.preventDefault();
        var formDataMain = {
            action: 'block_callback_modal_single',
            block_callback_name: $('#name_modal_single').val(),
            block_callback_phone: $('#tel_single').val(),
            single_id_object: $('#single_id_object').val()
        };
        var ajaxurl = '/wp-admin/admin-ajax.php';
        $.ajax({
            type: 'POST',
            url: ajaxurl,
            data: formDataMain,
            success: function(data) {
                console.log('Успешно');
                $('body').addClass('hide-b');
                $('#myFormSingle')[0].reset();

                $.fancybox.open({
                    src: '#thank_you_modal',
                    type: 'inline'
                });
            }
        });

        $.fancybox.close();
    });
});
jQuery( document ).ready(function($) {  
    $("[data-fancybox]").fancybox();
    $("#contact_block").submit(function(event) {
        event.preventDefault();

        var formData = {
            action: 'custom_gutenberg_block',
            custom_name_block: $('#custom_name_block').val(),
            custom_phone_block: $('#custom_phone_block').val(),
        };
        var ajaxurl = '/wp-admin/admin-ajax.php';
        $.ajax({
            type: 'POST',
            url: ajaxurl,
            data: formData,
            success: function(data) {
                console.log('Успешно');
                $('#contact_block')[0].reset();

                $.fancybox.open({
                    src: '#thank_you_modal',
                    type: 'inline'
                });
            }
        });
        $.fancybox.close();
    });
});
jQuery( document ).ready(function($) {  
    $("[data-fancybox]").fancybox();
    $("#contact_page").submit(function(event) {
        event.preventDefault();

        var formData = {
            action: 'custom_contact_page',
            custom_name: $('#custom_name').val(),
            custom_email: $('#custom_email').val(),
            custom_phone: $('#custom_phone').val(),
            custom_message: $('#custom_message').val()
        };
        var ajaxurl = '/wp-admin/admin-ajax.php';
        $.ajax({
            type: 'POST',
            url: ajaxurl,
            data: formData,
            success: function(data) {
                console.log('Успешно');
                $('#contact_page')[0].reset();

                $.fancybox.open({
                    src: '#thank_you_modal',
                    type: 'inline'
                });
            }
        });
        $.fancybox.close();
    });
});
/* ФОРМЫ КОНЕЦ*/



/* jQuery(document).ready(function($) {
    $(".select-group").click(function(event) {
    event.stopPropagation(); 
    var target = $(this).data("target");
    var $targetUL = $("#" + target);
    $('.select-custom-filter').removeClass('open-icon');
    if ($targetUL.hasClass("open")) {
        $targetUL.removeClass("open");
    } else {
        $(".select-custom-ul.open").removeClass("open");
        $targetUL.addClass("open");
        $(this).addClass('open-icon');
        $(this).find('.arrow').addClass('active');
    }
    }); */
jQuery(document).ready(function($) {
    $(".select-group").click(function(event) {
        $('.select-custom-filter').removeClass('open-icon');
        $(this).find('.select-custom-ul').toggleClass('open');
        $(this).find('.arrow').toggleClass('active');
        
        // Проверяем клик вне блока только после клика на .select-group
        $(document).mouseup(function(e) {
            var container = $(".select-custom-ul");
    
            // Если цель клика не является частью блока
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.removeClass('open');
                $('.arrow').removeClass('active');
            }
        });
    });
    $(document).on('click', function (e) {
        var $container = $('.themes-sort');
        var $sortingItem = $('.sorting__item');
        if (!$container.is(e.target) && $container.has(e.target).length === 0 && !$sortingItem.is(e.target) && $sortingItem.has(e.target).length === 0) {
            $container.removeClass('active-open');
            $sortingItem.removeClass('active');
        }
    });
    
    $('.sorting__item').on('click', function (e) {
        var $container = $('.themes-sort');
        $(this).toggleClass('active');
        if (!$container.hasClass('active-open')) {
            $container.addClass('active-open');
        }
        else {
            $container.removeClass('active-open');
        }
    });

// Добавляем обработчик события для всего документа
/* $(document).click(function(event) {
    var $target = $(event.target);
    if (!$target.closest('.select-custom-ul').length && !$target.hasClass('select-custom-filter')) {
        // Закрываем все открытые .select-custom-ul
        $(".select-custom-ul.open").removeClass("open");
        // Убираем класс open-icon у всех элементов с классом .select-custom-filter
        $('.select-custom-filter').removeClass('open-icon');
    }
}); */
});
jQuery(document).ready(function($) {
    $(window).on('load', function() {
    var mainFilterWidth = $('.main-filter').width();
    $('.form-rows').width(mainFilterWidth);
    });
});
jQuery(document).ready(function($) {
    $('.d-menu-links__item').click(function() {
        $(this).toggleClass('active');
    });
});
jQuery(document).ready(function($) {
    function saveCurrency(currency) {
        document.cookie = "currency=" + currency + "; path=/";
    }
    $(document).ready(function() {
        const $currencyButtons = $('.currency-btn');
        const $priceDisplays = $('.priceDisplay');
        $currencyButtons.on('click', function() {
        $currencyButtons.removeClass('active');
        $(this).addClass('active');
        const currency = $(this).data('currency');
        saveCurrency(currency);
            function getPriceTranslation(language) {
                switch(language) {
                    case 'ru':
                        return 'Цена';
                    case 'en':
                        return 'Price';
                    case 'uk':
                    default:
                        return 'Ціна';
                }
            }
            $priceDisplays.each(function() {
                const price = $(this).attr(`data-price-${currency}`);
                $(this).text(`${getPriceTranslation($(this).attr('lang'))}: ${price} ${currency}`);
            });
        });
    });
      function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }
    var currencyCookie = getCookie("currency");
    if (currencyCookie) {
        $('.currency-btn[data-currency="' + currencyCookie + '"]').trigger('click');
    }
});
/* MAIN */
/* jQuery(document).ready(function($) {
    $(document).on("click", ".seo-readmore__link", function () {
        $(this).prev(".seo-readmore__hide").toggleClass("active");
        $(this).parents(".readmore")
    }) 
}); */


/* jQuery(document).ready(function($) {

    if (window.innerWidth > 767) {
        // Проверяем наличие элемента с id 'typed'
        if ($('#typed').length > 0) {
            let typed;
            switch (typed_text_vars.current_lang) {
                case 'ru':
                    typed = new Typed('#typed', {
                        strings: ['Выбор недвижимости - это еще один шаг к вашей мечте'],
                        typeSpeed: 80,
                        backDelay: 600,
                        loop: false,
                        startDelay: 600,
                        showCursor: false
                    });
                    break;
                case 'ua':
                    typed = new Typed('#typed', {
                        strings: ['Вибір нерухомості - це ще один крок до вашої мрії'],
                        typeSpeed: 80,
                        backDelay: 600,
                        loop: false,
                        startDelay: 600,
                        showCursor: false
                    });
                    break;
                case 'en':
                    typed = new Typed('#typed', {
                        strings: ['Choosing a property is another step towards your dream'],
                        typeSpeed: 80,
                        backDelay: 600,
                        loop: false,
                        startDelay: 600,
                        showCursor: false
                    });
                    break;
            }
        }
    }

}); */
